<script setup>
// const { innerWidth } = useClientState();
const clientDelay = ref(false);

defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});

onMounted(() => {
    nextTick(() => {
        clientDelay.value = true;
    });
});
</script>
<template>
    <div v-if="clientDelay" class="FeaturedRealisations">
        <!--Desktop-->
        <div
            v-for="(realisation, realisationIndex) in data.realisationsEntries"
            :key="'realisation1'+realisationIndex"
            class="relative z-0 hidden bg-cover bg-fixed lg:grid lg:grid-cols-2 lg:gap-4"
            :style="{
                'background-image': clientDelay ? `url('${realisation.image[0].url}')` : '',
                'background-position':`${realisation.image[0].focalPoint[0] * 100}% ${realisation.image[0].focalPoint[1] * 100}%`
            }"
        >
            <!--<div v-if="realisation?.image?.[0]?.url" class="absolute left-0 top-0 z-0 size-full">
            <img
                v-if="realisation?.image?.[0]?.url"
                class="inset-0 block size-full object-cover"
                :src="realisation.image[0].url"
                :srcset="realisation.image[0].srcset"
                :alt="realisation.image[0].alt"
            >
        </div>-->
            <div class="top-0 self-start px-7 text-encre lg:sticky lg:z-30 lg:px-17">
                <span
                    v-if="data.showOverlay == 'afficherLeDegrade'"
                    aria-hidden
                    class="pointer-events-none absolute left-0 top-[40vh] z-10 block h-[60vh] w-screen bg-gradient-to-b from-perle/0 to-perle/90"
                />
                <div class="relative z-11 flex h-screen w-[560px] max-w-full flex-col">
                    <div class="flex h-full flex-col justify-between py-29 lg:py-22">
                        <ProjectInfos :project="realisation" />
                    </div>
                </div>
            </div>
            <div class="relative z-35">
                <div class="hidden h-screen lg:block" />
                <div
                    v-for="(image, imageIndex) in realisation.images"
                    :key="'image1'+imageIndex"
                    class="relative h-screen"
                >
                    <img
                        v-if="image?.url"
                        class="relative z-10 inline-block size-full object-cover"
                        :style="{
                            'object-position':`${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`
                        }"
                        :src="image.url"
                        :srcset="image.srcset"
                        :alt="image.alt"
                    >
                </div>
                <div class="absolute bottom-29 left-1/2 z-40 flex -translate-x-1/2 items-center justify-center lg:bottom-22">
                    <Button
                        v-for="link in data.links"
                        :key="link.id"
                        :to="link.url"
                        class="transition hover:bg-carmin hover:text-perle"
                    >
                        <span v-html="link.linkText" />
                        <span class="ml-2 inline-block fill-current" v-html="link.icon.inline" />
                    </Button>
                </div>
            </div>
        </div>
        <!--Mobile-->
        <Parallax
            id="parent"
            :enter="false"
            :leave="false"
            :animation="false"
            class="h-[100vh] overflow-clip lg:hidden"
            :style="{
                'height': `${(data.realisationsEntries[0].images.length+2)*100}vh`
            }"
            enter-leave-wrapper-classes="sticky top-0"
        >
            <ParallaxChild
                :animation="{
                    transform:[
                        'translateX(0) translateY(0) translateZ(0)',
                        `translateX(${-100+(100/(data.realisationsEntries[0].images.length+5))}%) translateY(0rem) translateZ(0)`
                    ]
                }"
                class="wrapper-avec-largeur"
                :style="{
                    'width': ((data.realisationsEntries[0].images.length+2) * 100)+'vw'
                }"
            >
                <div
                    v-for="(realisation, realisationIndex) in data.realisationsEntries"
                    :key="'realisation2'+realisationIndex"
                    class="relative ml-[80vw] flex"
                >
                    <span
                        v-if="data.showOverlay == 'afficherLeDegrade'"
                        aria-hidden
                        class="pointer-events-none absolute left-0 top-[40vh] z-10 block h-[60vh] w-screen bg-gradient-to-b from-perle/0 to-perle/90"
                    />
                    <div class="relative z-11 h-screen w-screen self-start overflow-hidden px-7 py-29 text-encre">
                        <div class="relative z-10 flex h-[75vh] w-screen max-w-full flex-col justify-between">
                            <h3
                                class="text-3xl font-bold uppercase"
                                v-html="realisation.title"
                            />
                            <div class="text-xs">
                                <div
                                    class="relative mb-7 border-b border-black font-bold uppercase"
                                >
                                    <span
                                        v-html="realisation.sectionTitle2"
                                    />
                                </div>
                                <div>
                                    <table class="w-full">
                                        <thead>
                                            <tr>
                                                <th
                                                    v-for="(column, cIndex) in realisation.table"
                                                    :key="'table2-head'+cIndex"
                                                    class="text-left uppercase"
                                                >
                                                    {{ column.libelle }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    v-for="(column, cIndex2) in realisation.table"
                                                    :key="'table2-cell'+cIndex2"
                                                    class="text-left font-light uppercase"
                                                >
                                                    {{ column.infos }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <img
                            v-if="realisation.image?.[0]?.url"
                            class="absolute inset-0 size-full object-cover"
                            :style="{
                                'object-position':`${realisation.image[0].focalPoint[0] * 100}% ${realisation.image[0].focalPoint[1] * 100}%`
                            }"
                            :src="realisation.image[0].url"
                            :srcset="realisation.image[0].srcset"
                            :alt="realisation.image[0].alt"
                        >
                    </div>

                    <div
                        v-for="(image, imageIndex) in realisation.images"
                        :key="'image2'+imageIndex"
                        class="relative h-screen w-screen"
                    >
                        <img
                            v-if="image?.url"
                            class="absolute inset-0 size-full object-cover"
                            :style="{
                                'object-position':`${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`
                            }"
                            :src="image.url"
                            :srcset="image.srcset"
                            :alt="image.alt"
                        >
                    </div>
                    <div class="absolute bottom-22 right-[70vw] translate-x-1/2">
                        <Button
                            v-for="link in data.links"
                            :key="link.id"
                            :to="link.url"
                            class="transition hover:bg-carmin hover:text-perle"
                        >
                            <span v-html="link.linkText" />
                            <span class="ml-2 inline-block fill-current " v-html="link.icon.inline" />
                        </Button>
                    </div>
                </div>
            </ParallaxChild>
        </Parallax>
    </div>
</template>
